import jwtDecode from 'jwt-decode'
import { api } from '~/utils/axios-instances'

export default class Auth {
	constructor() {
		this.signin = this.signin.bind(this)
		this.signout = this.signout.bind(this)
		this.validate = this.validate.bind(this)
		this._setSession = this._setSession.bind(this)
		this.loadDataFromToken = this.loadDataFromToken.bind(this)
	}

	signin(email, password) {
		return new Promise((resolve, reject) => {
			api.post('/login', {
				email,
				password,
			})
			.then(data => {
				this.loadDataFromToken(data.data)
				resolve(this.profile)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	loadDataFromToken(token) {
		this.rawToken = token
		this.decoded = jwtDecode(this.rawToken)
		this.profile = JSON.parse(this.decoded.userdata)
		console.log('Logged in as:', this.profile)
		// console.log('profile', this.profile)
		// console.log('Name:', this.profile.Firstname+' '+this.profile.Lastname)
		// console.log('Email:', this.profile.Email)
		this._setSession()
	}

	validate() {
		return new Promise((resolve, reject) => {
			const token = localStorage.getItem('id_token')
			if (token) {
				api.post('/validatetoken', {}, {
					headers: {
						'Authorization': `Bearer ${token}`
					},
					responseType: 'text',
				})
				.then(() => {
					this.loadDataFromToken(token)
					resolve(this.profile)
				})
				.catch(() => {
					reject('You have been disconnected from the server')
				})
			}
			else {
				reject(undefined)
			}
		})
	}

	signout() {
		const token = localStorage.getItem('id_token')
		if (token) {
			api.post('/logout', {}, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			localStorage.removeItem('id_token')
			delete api.defaults.headers.common['Authorization']
			delete api.defaults.headers.common['Content-Type']
			// TODO: redirect to /login
		}
	}

	_setSession() {
		localStorage.setItem('id_token', this.rawToken)
		api.defaults.headers.common['Authorization'] = this.rawToken
		api.defaults.headers.common['Content-Type'] = 'application/json'
	}
}