export default {
	items: [
		{
			name: 'Dashboard',
			url: '/dashboard',
			icon: 'fa fa-info-circle',
		},
		{
			name: 'Utilisateurs',
			url: '/users/list',
			icon: 'fa fa-user-circle',
		},
		{
			name: 'Configurations',
			url: '/configuration',
			icon: 'fa fa-cogs',
			children: [
				{
					name: 'Gammes',
					url: '/configuration/gammes',
					icon: 'fa fa-caret-right',
				},
				{
					name: 'Modules',
					url: '/configuration/modules',
					icon: 'fa fa-caret-right',
				},
				{
					name: 'Modules config',
					url: '/configuration/modules/config',
					icon: 'fa fa-caret-right',
				},
				{
					name: 'Composants',
					url: '/configuration/composants',
					icon: 'fa fa-caret-right',
				},
			]
		},
		{
			name: 'Stocks',
			url: '/stocks',
			icon: 'fa fa-th-large',
			attributes: { disabled: true },
		},
		{
			name: 'Devis',
			url: '/estimate/list',
			icon: 'fa fa-file-text',
		},
		{
			name: 'Paiement',
			url: '/paiements',
			icon: 'fa fa-credit-card',
		},
	],
};
	