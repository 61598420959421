import { TOASTR } from '~/actions/types'

let toastrReference = null

const toastrReducer = (state = {}, action) => {
	switch (action.type) {
        case TOASTR.REF:
            toastrReference = action.toastrRef
            return state

        case TOASTR.NOTIFICATION:
            toastrReference[action.toastr](action.message, action.title, {
                closeButton: true,
                showAnimation: 'animated faster slideInDown',
                hideAnimation: 'animated faster slideOutRight',
                ...action.options,
            })
            return state

        default:
			return state
	}
}

export default toastrReducer
