import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { BrowserRouter as Router } from 'react-router-dom'
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import './i18n'

import reducers from './reducers'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'development') {
	/*const { scanForProblems } = require('accessibilityjs')
	function logError(error) {
		error.element.classList.add('accessibility-error')
		error.element.style.outline = '5px solid red'
		error.element.addEventListener('click', function () {
			alert(`${error.name}\n\n${error.message}`)
		})//, {once: true})
	}

	scanForProblems(document, logError)*/

	const a11y = require('react-a11y').default

	/*a11y(React, ReactDOM, {
		rules: {
			'aria-role': 'error',
			'aria-unsupported-elements': 'error',
			'click-events-have-key-events': 'error',
			'hidden-uses-tabindex': 'error',
			'img-redundant-alt': 'error',
			'img-uses-alt': 'error',
			'interactive-supports-focus': 'error',
			'label-has-for': 'error',
			'mouse-events-have-key-events': 'error',
			'no-access-key': 'error',
			'no-hash-ref': 'error',
			'no-onchange': 'error',
			'onclick-uses-role': 'error',
			'tabindex-no-positive': 'error',
			'tabindex-uses-button': 'error',
		}
	})*/
}

const store = createStore(reducers, composeWithDevTools(
	applyMiddleware(reduxThunk)
))

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
