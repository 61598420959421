import { AUTH } from './types'
import Auth from '~/utils/Auth'

const auth = new Auth()

export function signinUser({ email, password }, callback) {
	return function(dispatch) {
		dispatch({ type: AUTH.AUTH_IN_PROGRESS })

		auth.signin(email, password)
		.then(profile => {
			dispatch({ type: AUTH.AUTH_USER, profile })
			return callback(null)
		})
		.catch(error => {
			dispatch(authError(error))
			return callback(error)
		})
	}
}

export function authError(error) {
	const timestamp = Date.now()
	return {
		type: AUTH.AUTH_ERROR,
		error,
		timestamp,
	}
}

export function signoutUser() {
	auth.signout()
	return { type: AUTH.UNAUTH_USER }
}

export function cleardown() {
	return { type: AUTH.CLEARDOWN }
}

export function validateUser(callback) {
	return function(dispatch) {
		dispatch({ type: AUTH.VALIDATE_IN_PROGRESS })

		auth.validate()
		.then(profile => {
			dispatch({ type: AUTH.AUTH_USER, profile })
			return callback(null)
		})
		.catch(error => {
			if (error) {
				dispatch(authError(error))
				return callback(error)
			}
			else {
				dispatch({ type: AUTH.UNAUTH_USER })
				return callback({message: 'User non authorized to access the application.'})
			}
		})
	}
}