import React, { Component } from 'react'
import PropTypes from 'prop-types'

class DefaultFooter extends Component {
	render() {
		// eslint-disable-next-line
		const { children, ...attributes } = this.props

		return (
			<React.Fragment>
				<span><a href="https://madera.io">Madera</a> &copy; 2018.</span>
			</React.Fragment>
		)
	}
}

DefaultFooter.propTypes = {
	children: PropTypes.node,
}
DefaultFooter.defaultProps = {}

export default DefaultFooter
