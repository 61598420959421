import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
// import 'moment/min/moment-with-locales'

import translationEN from '~/locales/en.json'
import translationFR from '~/locales/fr.json'

const momentLocales = { gb: 'en-gb' }

// the translations
const resources = {
	gb: { translation: translationEN },
	fr: { translation: translationFR },
}

function getMomentLang(newLang) {
	let mlang = newLang
	if (newLang in momentLocales) {
		mlang = momentLocales[newLang]
	}
	return mlang
}

i18n
	.use(reactI18nextModule) // passes i18n down to react-i18next
	.on('languageChanged', function(newLang) {
		moment.locale(getMomentLang(newLang))
	})
	.init({
		// debug: true,
		resources,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		lng: 'fr',
		fallbackLng: 'fr',
	})
	
export default i18n
