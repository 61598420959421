import React, { Component } from 'react'
import { connect as reduxConnect } from 'react-redux'

export default function(ComposedComponent) {
	class Authentication extends Component {
		componentWillMount() {
			if (!this.props.authenticated) {
				this.props.history.push('/login')
			}
		}

		componentWillUpdate(nextProps) {
			// TODO: wait afer loading
			// console.log('AUTH_USER', nextProps)
			if (!nextProps.authenticated) {
				this.props.history.push('/login')
			}
		}

		render() {
			return <ComposedComponent {...this.props} />
		}
	}

	function mapStateToProps(state) {
		return { authenticated: state.auth.authenticated }
	}

	return reduxConnect(mapStateToProps)(Authentication)
}