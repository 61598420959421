import React, { Component, Suspense } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import {
	AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react'

import { Container, Row, Col } from 'reactstrap'

import navigation from '~/components/nav'
import routes from '~/utils/routes'
import Header from '~/components/partials/header'
import Footer from '~/components/partials/footer'
import LoadingComponent from '~/components/actions/loadingComponent'
import NotFound from '~/routes/not-found'

class PrivateLayout extends Component {
	render() {
		return (
			<div className="app">
				<AppHeader fixed>
					<Header />
				</AppHeader>
				<div className="app-body">
					<AppSidebar fixed display="lg">
						<AppSidebarHeader />
						<AppSidebarForm />
						<AppSidebarNav navConfig={navigation} {...this.props} />
						<AppSidebarFooter />
						<AppSidebarMinimizer />
					</AppSidebar>
					<main className="main">
						<AppBreadcrumb appRoutes={routes}/>
						<Container fluid>

							<Suspense fallback={LoadingComponent}>
								<Switch>
									{routes.map((route, idx) => {
										return route.component ? (
											<Route
												key={idx}
												path={route.path}
												exact={route.exact}
												name={route.name}
												render={props => (
													<route.component {...props} />
												)} />
										) : (null)
									})}
									{/* <Route component={NotFound} /> */}
									{/* <Redirect from="/" to="/dashboard" /> */}
								</Switch>
							</Suspense>
						</Container>
					</main>
				</div>
				<AppFooter>
					<Footer />
				</AppFooter>
			</div>
        )
	}
}

export default withRouter(PrivateLayout)
