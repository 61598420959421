import React, { Component } from 'react'
import { Button } from 'reactstrap'

class Loading extends Component {
	render() {
		if (this.props.error) {
			return <div className="loading-error">Error! <Button onClick={this.props.retry}>Retry</Button></div>
		} else if (this.props.timedOut) {
			return <div>Taking a long time... <Button onClick={this.props.retry}>Retry</Button></div>
		} else if (this.props.pastDelay) {
			return <div className="loading">Loading...</div>
		} else {
			return null;
		}
	}
}

export default Loading