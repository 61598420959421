import React from 'react'
import { Redirect } from 'react-router-dom'

import requireAuth from '~/components/actions/requireAuth'
import Loading from '~/components/actions/loading'

const Dashboard = Loading({ loader: () => import('~/routes/dashboard') })
const Settings = Loading({ loader: () => import('~/routes/settings') })
const UsersList = Loading({ loader: () => import('~/routes/users/list') })
const UsersRoles = Loading({ loader: () => import('~/routes/users/roles') })
const Paiements = Loading({ loader: () => import('~/routes/paiements/index') })
const EstimateList = Loading({ loader: () => import('~/routes/estimates/list') })
const EstimateCreation = Loading({ loader: () => import('~/routes/estimates/creation') })
const EstimateDetails = Loading({ loader: () => import('~/routes/estimates/details') })
const Configurations = Loading({ loader: () => import('~/routes/configurations/') })
const ConfigurationGammes = Loading({ loader: () => import('~/routes/configurations/gammes') })
const ConfigurationModules = Loading({ loader: () => import('~/routes/configurations/modules') })
const ConfigurationModulesConfig = Loading({ loader: () => import('~/routes/configurations/modules/config') })
const ConfigurationComposants = Loading({ loader: () => import('~/routes/configurations/composants') })

const RedirectDashboardFct = () => <Redirect from="/" to="/dashboard" />

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
	{ path: '/', exact: true, name: 'Index', component: requireAuth(RedirectDashboardFct) },
	{ path: '/dashboard', name: 'Dashboard', component: requireAuth(Dashboard) },
	{ path: '/settings', exact: true, name: 'Settings', component: requireAuth(Settings) },
	{ path: '/users/list', exact: true, name: 'Users list', component: requireAuth(UsersList) },
	{ path: '/users/roles', exact: true, name: 'Roles list', component: requireAuth(UsersRoles) },

	{ path: '/paiements', name: 'Paiements', component: requireAuth(Paiements) },
	
	{ path: '/estimate/list', name: 'Estimates list', component: requireAuth(EstimateList) },
	{ path: '/estimate/creation', name: 'Estimates creation', component: requireAuth(EstimateCreation) },
	{ path: '/estimate/details', name: 'Estimates details', component: requireAuth(EstimateDetails) },

	{ path: '/configuration', exact: true, name: 'Configuration', component: requireAuth(Configurations) },
	{ path: '/configuration/gammes', name: 'Gammes', component: requireAuth(ConfigurationGammes) },
	{ path: '/configuration/modules', exact: true, name: 'Modules', component: requireAuth(ConfigurationModules) },
	{ path: '/configuration/modules/config', exact: true, name: 'Config', component: requireAuth(ConfigurationModulesConfig) },
	{ path: '/configuration/composants', name: 'Composants', component: requireAuth(ConfigurationComposants) },
]

export default routes
