const types = {
	TOASTR: {
		REF: 'TOASTR:REF',
		NOTIFICATION: 'TOASTR:NOTIFICATION',
	},
	AUTH: {
		AUTH_USER: 'AUTH:AUTH_USER',
		UNAUTH_USER: 'AUTH:UNAUTH_USER',
		AUTH_IN_PROGRESS: 'AUTH:AUTH_IN_PROGRESS',
		AUTH_ERROR: 'AUTH:AUTH_ERROR',
		CLEARDOWN: 'AUTH:CLEARDOWN',
		VALIDATE_IN_PROGRESS: 'AUTH:VALIDATE_IN_PROGRESS',
	},
}

export default types

export const TOASTR = types.TOASTR
export const AUTH = types.AUTH