import React, { Component } from 'react'
import { connect as reduxConnect } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastr'
import '~/App.scss'

import Loading from '~/components/actions/loading'
import * as actionsAuth from '~/actions/auth'
import { toastrRef } from '~/actions/toastr'

import PrivateLayout from '~/routes/private-layout'
const Login = Loading({ loader: () => import('~/routes/login') })

class App extends Component {
	constructor(props) {
		super(props)

		this.bindToastrRef = this.bindToastrRef.bind(this)
	}

	bindToastrRef(toastr) {
		this.props.toastrRef(toastr)
	}

	componentDidMount() {
		const beforeUrl = this.props.history.location.pathname
		this.props.validateUser((err) => {
			if (err) {
				console.log('Can\'t access to application')
				this.props.history.push('/login')
			}
			else {
				console.log('Access granted to application')
				this.props.history.push(beforeUrl)
			}
		})
	}

	render() {
		return (
			<React.Fragment>
				<ToastContainer ref={this.bindToastrRef} className="toast-top-right" preventDuplicates={false} />

				{/* { !this.props.auth.authenticated && <Redirect from="/" to="/login" /> } */}
				<Route exact path="/login" component={Login} />
				{ this.props.auth.authenticated && <PrivateLayout /> }
			</React.Fragment>
		)
	}
}

export default withRouter(reduxConnect(
	(state) => state,
	{...actionsAuth, toastrRef}
)(App))