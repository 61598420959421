import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'

const defaultProgressBarConfig = {
	showSpinner: false,
}

// Create progress bar for global axios instance
loadProgressBar(Object.assign({}, defaultProgressBarConfig))

export const api = axios.create({
	baseURL: `https://madera.sparxxe.com/api`,
})

// Create progress bar for specifics axios instances
loadProgressBar(Object.assign({}, defaultProgressBarConfig), api)
