import { TOASTR } from './types'

export const toastrRef = (toastrRef) => ({
	type: TOASTR.REF,
	toastrRef,
})

export const toastr = ['success', 'error', 'warning', 'info'].reduce((acc, toastr) => {
	acc[toastr] = (message, title, options) => ({
		type: TOASTR.NOTIFICATION,
		toastr,
		message,
		title,
		options,
	})
	return acc
}, {})
