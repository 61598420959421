import { AUTH } from '~/actions/types'

const INIT = {
	error: '',
	timestamp: '',
	loading: false,
	authenticated: false,
	profile: undefined,
}

export default function(state = {}, action) {
	switch (action.type) {
		case AUTH.CLEARDOWN:
			return { ...state, ...INIT }
		case AUTH.AUTH_USER:
			return { ...state, ...INIT, authenticated: true, profile: action.profile }
		case AUTH.AUTH_IN_PROGRESS:
		case AUTH.VALIDATE_IN_PROGRESS:
			return { ...state, ...INIT, loading: true }
		case AUTH.UNAUTH_USER:
			return { ...state, ...INIT }
		case AUTH.AUTH_ERROR:
			return { ...state, ...INIT, error: action.error, timestamp: action.timestamp }
		default:
			return state
	}
}