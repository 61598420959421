import React, { Component } from 'react'
import { connect as reduxConnect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap'
import PropTypes from 'prop-types'
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import { withNamespaces } from 'react-i18next'

import * as authActions from '~/actions/auth'
import { api } from '~/utils/axios-instances'

import logo from '~/assets/img/logo.png'
import sygnet from '~/assets/img/sygnet.png'

class Header extends Component {
	state = { quotationCount: 0 }

	componentDidMount() {
		api.get('/tools/countQuotation')
		.then(response => this.setState({quotationCount: response.data}))
		.catch(console.log)
	}

	logout = () => {
		this.props.signoutUser()
		this.props.history.replace('/login')
	}

	goToEstimates = () => {
		this.props.history.push('/estimate/list')
	}

	goToSettings = () => {
		this.props.history.push('/settings')
	}

	changeLanguage = (lang) => {
		this.props.i18n.changeLanguage(lang)
	}

	render() {
		// eslint-disable-next-line
		const { children, auth, t, i18n, ...attributes } = this.props
		const { profile } = auth

		return (
			<React.Fragment>
				<AppSidebarToggler className="d-lg-none" display="md" mobile />
				<AppNavbarBrand
					full={{ src: logo, width: 89, height: 25, alt: 'Madera Logo' }}
					minimized={{ src: sygnet, width: 30, height: 30, alt: 'Madera Logo' }}
				/>
				<Nav className="ml-auto" navbar>
					
					<NavItem className="d-md-down-none">
						<Link to="/settings"><i className="fa fa-cog"></i></Link>
					</NavItem>
					<AppHeaderDropdown direction="down">
						<DropdownToggle nav>
							<img src={profile.Avatar ? profile.Avatar : '/assets/img/avatars/6.jpg'} className="img-avatar" alt={profile.Email} />
						</DropdownToggle>
						<DropdownMenu right style={{ right: 'auto' }}>
							<DropdownItem header tag="div"><strong>{`${profile.Firstname} ${profile.Lastname}`}</strong></DropdownItem>
							<DropdownItem style={{cursor:'pointer'}} onClick={this.goToEstimates}><i className="fa fa-envelope-o"></i> {t('utils.my-invoices')}<Badge color="success">{this.state.quotationCount}</Badge></DropdownItem>
							<DropdownItem header tag="div" className="text-center"><strong>{t('utils.other')}</strong></DropdownItem>
							<DropdownItem style={{cursor:'pointer'}} onClick={this.goToSettings}><i className="fa fa-wrench"></i> {t('utils.settings')}</DropdownItem>

							<DropdownItem header tag="div"><strong>{t('utils.languages')}</strong></DropdownItem>
							{ Object.keys(i18n.options.resources).filter(e => e !== i18n.language).map((e, i) => <DropdownItem key={i} onClick={() => this.changeLanguage(e)} style={{cursor:'pointer'}}><span className={`flag-icon flag-icon-${e}`}></span> {t(`langs.${e}`)}</DropdownItem>) }
							<DropdownItem header tag="div"><strong>{t('utils.action')}</strong></DropdownItem>
							<DropdownItem onClick={this.logout} style={{cursor:'pointer'}}><i className="fa fa-lock"></i> {t('actions.logout')}</DropdownItem>
						</DropdownMenu>
					</AppHeaderDropdown>
				</Nav>
			</React.Fragment>
		)
	}
}

Header.propTypes = {
	children: PropTypes.node,
}
Header.defaultProps = {}

export default withRouter(reduxConnect(
	(state) => state,
	{...authActions}
)(withNamespaces()(Header)))
